<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/pass.png" class="icon" />
            <input type="number" oninput="if(value.length > 4)value = value.slice(0, 4)" v-model="password" class="input" placeholder="请输入4位数字" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
        <div class="button1 primary1 mt1" @click="goback">取消</div>
      </div>
      <div class="xg">  
        <div class="textxg1">重要提示</div>   
        <div class="textxg">设置密码提交后，人员上岗需使用此密码。</div>   
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    /*let { data } = await this.axios.get('/ctid/tlService/getmm');
    if (data.flag === '0') {
      this.password = data.data;
    }*/    
  },
  data() {
    return {
      state:false,
      password: ''
    };
  },
  methods: {
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if(isEmpty(this.password)) {
        Dialog({ message: '请输入密码' });
        this.state = false;
        /*Dialog.confirm({
          showCancelButton: false,
          message: '密码清空后，上岗需管理员审批！'         
        }).then(async () =>{
          let { data } = await this.axios.post(
            '/medicines/yd_tlService/jy_setmm',
            qs.stringify({ pw: this.password})
          );

          //Dialog({ message: data.message });
          Toast(data.message);
          this.$router.push('/position/list');
        });*/
      }else{
        Dialog.confirm({
          showCancelButton: false,
          message: '密码修改后，上岗将使用此密码！'
        }).then(async () =>{
          //this.$router.push('/position/bind_post'); 
          let { data } = await this.axios.post(
            '/medicines/yd_tlService/jy_setmm',
            qs.stringify({ pw: this.password})
          );
          if (data.flag === '0') {
            Toast(data.message);
            this.state = false;
            this.$router.replace('/refueling/my');
          } else {
            Toast(data.message);
            this.state = false;
          }

        });     
      }

    },
    goback() {
      this.$router.replace({path:'/refueling/my', query:{}});
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 229px;
}
.content {
  margin-top: 60px;
}
.xg {
  width: 600px;
  overflow: hidden;
  margin: 0px auto;
  padding: 80px 0px 20px 0px;
  .textxg1 {
    width: 300px;
    height: 80px;
    display: block;
    text-align: center;
    margin: 0px auto;
    color: #b80808;
    font-size: 28px;
  }
  .textxg {
    width: 532px;
    height: 50px;
    display: block;
    margin: 0px auto;
    font-size: 28px;
  }
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary1 {
    background: linear-gradient(90deg, rgba(92, 197, 216, 1) 0%, rgba(12, 149, 173, 1) 100%);
    color: #fff;
  }
  &.mt1 {
    margin-top: 15px;
  }
}
</style>